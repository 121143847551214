import React, { useState } from "react";

const ContactForm = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    city: "",
    query: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData); // Print the form data to the console
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      city: "",
      query: "",
    }); // Clear the form
    setShowModal(true); // Show the thank-you modal
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div
      id="getintouch"
      className="bg-[#110931] flex items-center justify-center p-[32px] lg:p-[40px_110px_40px_110px]"
    >
      <div className="lg:max-w-5xl w-full bg-[#0B0527] lg:m-16 p-6 lg:p-12 rounded-lg shadow-lg">
        <h2 className="text-3xl lg:text-[45px] font-semibold tracking-wide text-white text-center mb-8">
          Get in <span className="text-[#E22028]"> Touch </span>
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex flex-col sm:flex-row gap-4">
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              required
              className="w-full sm:w-1/2 p-3 rounded-md bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              required
              className="w-full sm:w-1/2 p-3 rounded-md bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
            className="w-full p-3 rounded-md bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone Number"
            required
            className="w-full p-3 rounded-md bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            placeholder="City"
            required
            className="w-full p-3 rounded-md bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <textarea
            name="query"
            value={formData.query}
            onChange={handleChange}
            placeholder="Tell Your Query"
            required
            className="w-full p-3 h-24 rounded-md bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
          ></textarea>
          <button
            type="submit"
            className="w-full p-3 rounded-md bg-[#1791C8] text-black font-semibold hover:bg-[#25789f] transition"
          >
            Submit
          </button>
        </form>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg max-w-sm w-full text-center">
            <h3 className="text-xl font-semibold mb-4">Thank You!</h3>
            <p>We will contact you soon.</p>
            <button
              onClick={closeModal}
              className="mt-4 px-4 py-2 bg-[#1791C8] text-white rounded-md hover:bg-[#25789f] transition"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
