import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="p-[32px] lg:p-[80px_110px_80px_110px] flex items-center justify-center bg-[#0b0527] text-gray-200">
      <div className="flex flex-col gap-4 lg:pl-44 lg:pr-44">
        <h1 className="text-3xl font-bold text-center mb-4">
          Terms and Conditions
        </h1>

        <h2 className="text-md md:text-2xl font-semibold mt-6">Usage Rights</h2>
        <p className="mb-4">
          Our courses are provided solely for personal and educational use. Any
          redistribution, sharing, or commercial use of course materials is
          strictly prohibited and may result in legal action. Access to our
          content is intended exclusively for the registered individual and must
          not be transferred, resold, or shared with others under any
          circumstances.
        </p>

        <h2 className="text-md md:text-2xl font-semibold mt-6">
          Account Responsibility
        </h2>
        <p className="mb-4">
          As a registered user, you are solely responsible for maintaining the
          security of your login credentials. Sharing your account details with
          others is not allowed. If you suspect any unauthorized use of your
          account, please notify us immediately to secure your access and
          protect your information. We cannot be held liable for unauthorized
          access due to credential misuse.
        </p>

        <h2 className="text-md md:text-2xl font-semibold mt-6">
          Payment Terms
        </h2>
        <p className="mb-4">
          All payments for courses and services must be completed at the time of
          purchase. We accept various payment methods, including credit cards,
          debit cards, and select online payment gateways. By completing a
          transaction, you agree to the final amount displayed, which will not
          be adjusted after purchase. Ensure your payment information is
          accurate to avoid transaction issues.
        </p>

        <h2 className="text-md md:text-2xl font-semibold mt-6">
          No Refund Policy
        </h2>
        <p className="mb-4">
          All sales are considered final upon purchase. We operate with a strict
          no-refund policy for digital content, as users receive immediate
          access to course materials. Please review all course information
          before purchase to ensure it meets your expectations, as refunds,
          cancellations, or exchanges are not provided post-transaction.
        </p>

        <h2 className="text-md md:text-2xl font-semibold mt-6">
          Intellectual Property
        </h2>
        <p className="mb-4">
          All course materials, including videos, text, graphics, and other
          content, are the intellectual property of our platform and its
          licensors. Copying, modifying, distributing, or displaying course
          content outside our platform without permission is strictly prohibited
          and may result in legal action.
        </p>

        <h2 className="text-md md:text-2xl font-semibold mt-6">
          Course Modification and Discontinuation
        </h2>
        <p className="mb-4">
          We reserve the right to modify or discontinue any course, feature, or
          service at any time without notice. In case of a course update,
          current participants may continue with the original material or
          transition to the updated content. We strive to ensure content
          relevancy but cannot guarantee uninterrupted access to specific
          courses indefinitely.
        </p>

        <h2 className="text-md md:text-2xl font-semibold mt-6">
          Termination of Access
        </h2>
        <p className="mb-4">
          We reserve the right to terminate or suspend your access to our
          platform if you violate any terms, including but not limited to
          unauthorized sharing of course content or account misuse. We may
          terminate access without prior notice, and any fees paid will not be
          refunded in such cases.
        </p>

        <h2 className="text-md md:text-2xl font-semibold mt-6">User Conduct</h2>
        <p className="mb-4">
          We expect all users to engage respectfully on our platform, including
          in any discussion forums or live sessions. Inappropriate behavior,
          including offensive language, harassment, or spamming, is not
          tolerated and may result in suspension or termination of access
          without refund.
        </p>

        <h2 className="text-md md:text-2xl font-semibold mt-6">
          Disclaimer of Warranties
        </h2>
        <p className="mb-4">
          Our courses are provided "as is" without any warranties, express or
          implied. We make no guarantees regarding the accuracy, reliability, or
          applicability of course content for specific goals. While we strive to
          provide quality education, outcomes may vary, and we disclaim
          liability for results or performance.
        </p>

        <h2 className="text-md md:text-2xl font-semibold mt-6">
          Governing Law
        </h2>
        <p className="mb-4">
          These terms and any disputes arising from your use of our platform
          will be governed by the laws of the jurisdiction in which our company
          is based. By agreeing to these terms, you consent to the jurisdiction
          of our local courts for any legal proceedings related to your use of
          our services.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
