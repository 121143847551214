import React from "react";
import contactUsBgImg from "../../Assests/Home/ContactSectionBgImg.svg";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { BsPhoneFill } from "react-icons/bs";

const ContactUs = () => {
  return (
    <div
      id="contact"
      className=" bg-cover  bg-no-repeat text-white   p-[32px] flex flex-col gap-16 md:gap-8   lg:p-[40px_110px_80px_110px]"
      style={{ backgroundImage: `url(${contactUsBgImg}) ` }}
    >
      <h2
        data-aos="fade-right"
        className="mulish-thin text-3xl text-center  tracking-[1px] lg:text-[45px] "
      >
        <span className="text-[#E22028]"> Contact </span>Us
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-16 md:gap-8 text-[#858293] text-[14px]  font-sans">
        <div
          data-aos="fade-right"
          className="flex flex-col  items-center xl:px-16 gap-4 md:gap-4 "
        >
          <BsPhoneFill className="h-6 w-6 text-gray-300 molish-thin" />
          <p className="text-base tracking-widest font-light">
            <a
              href="tel: +91 9462049921"
              target="_blank"
              rel="noopener noreferrer"
            >
              +91 9462049921
            </a>
          </p>
        </div>

        <div
          data-aos="fade-top"
          className="flex flex-col   xl:px-16 gap-4 md:gap-4   items-center "
        >
          <FaMapMarkerAlt className="h-6 w-6 text-gray-300 " />
          <p className="text-base font-light px-10 md:px-0 text-center ">
            Hanuman Shahai, Ward No-15, Ghasipura, Teh-Khandela, Sikar,
            Rajasthan 332708
          </p>
        </div>

        <div
          data-aos="fade-left"
          className="flex flex-col  items-center xl:px-16 gap-4 md:gap-4 "
        >
          <FaEnvelope className="h-6 w-6 text-gray-300" />
          <p className="text-base tracking-widest font-light">
            <a target="_blank" href="mailto:ammarketing921@gmail.com">
              ammarketing921@gmail.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
