import React from "react";
import UnlockUnlimited from "../../Components/Home/UnlockUnlimited";
import WhyChooseUs from "../../Components/Home/WhyChooseUs";
import OurCourses from "../../Components/Home/OurCourses";
import OurAchievements from "../../Components/Home/OurAchievements";
import LatestFeaturedPlans from "../../Components/Home/LatestFeaturedPlans";
import OurTestimonial from "../../Components/Home/OurTestimonial";
import ContactUs from "../../Components/Home/ContactUs";
import ContactForm from "../../Components/Home/GetInTouch";

const Home = () => {
  return (
    <div className="w-full h-full overflow-hidden">
      <UnlockUnlimited />
      <WhyChooseUs />
      <OurCourses />
      <OurAchievements />
      <LatestFeaturedPlans />
      <ContactUs />

      <OurTestimonial />
      <ContactForm />
    </div>
  );
};

export default Home;
