import React from "react";
import unluckFirstImg from "../../Assests/Home/firstBgImg.svg";
import unlockSideImg from "../../Assests/Home/unluckSideImg.svg";
import { FaPlayCircle } from "react-icons/fa";
import { IoMdArrowForward } from "react-icons/io";
import { scrollToSection } from "../../Utils/useCountHelper";

const UnlockUnlimited = () => {
  return (
    <div
      id="home"
      className=" bg-cover text-white grid grid-cols-1 md:grid-cols-2 gap-8
      lg:grid-cols-2 p-[32px] lg:p-[45px__110px]"
      style={{ backgroundImage: `url(${unluckFirstImg}) ` }}
    >
      <div className="w-full h-full  grid gap-6 md:gap-2  ">
        <div className="bg-gradient-to-r w-fit  h-fit py-2 px-3  flex  items-center rounded-l-full  from-[#5392AF]  to-[#110C30]">
          <h3
            data-aos="fade-left"
            className="oregano-regular  tracking-[4px]  md:tracking-[6px] "
          >
            100+ daily active users
          </h3>
        </div>
        <h2
          data-aos="fade-right"
          className="mulish-thin text-3xl leading-10 xl:text-5xl xl:leading-[60px] tracking-[1px] font-bold"
        >
          "Unlock Your Learning Potential with
          <span className="text-[#E22028]"> AJAY GULSHAN MARKETING LLP</span>"
        </h2>
        <p
          data-aos="fade-right"
          className="mulish-thin text-md   text-gray-300  lg:text-[18px] lg:leading-[34px] tracking-wider "
        >
          Master the skills of digital marketing and stock trading with our
          comprehensive courses, designed to equip you with the knowledge and
          strategies needed for success in today's competitive landscape.
        </p>
        <div className="flex  gap-4  items-center">
          <button
            onClick={() => scrollToSection("features")}
            className="py-3 text-xs md:text-sm px-6 flex gap-2 justify-center items-center bg-[#1791C8] hover:bg-[#25789f] text-white rounded-full"
          >
            Get started <IoMdArrowForward />
          </button>
          <button
            onClick={() => scrollToSection("getintouch")}
            className="py-3 text-xs md:text-sm px-6 flex gap-2 justify-center items-center bg-white hover:bg-[#72797d] text-black rounded-full"
          >
            How it works <FaPlayCircle />
          </button>
        </div>
      </div>
      <div data-aos="fade-right" className="">
        <img
          className="w-full  object-cover"
          src={unlockSideImg}
          alt="unluckSideImg"
        />
      </div>
    </div>
  );
};

export default UnlockUnlimited;
