import React, { useEffect } from "react";
import Navbar from "../../Components/Layout/Navbar";
import Footer from "../../Components/Layout/Footer";
import AOS from "aos";
import { useLocation } from "react-router-dom";
import "aos/dist/aos.css";

const NonAuthLayout = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(300, 0);
    AOS.init({
      offset: 100,
      duration: 800,
      easing: "ease-in-out",
      delay: 50,
    });
  }, [location.pathname]);

  return (
    <React.Fragment>
      <Navbar />
      {props.children}
      <Footer />
    </React.Fragment>
  );
};

export default NonAuthLayout;
