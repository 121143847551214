import React from "react";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";

const NotFound = () => {
  return (
    <div className=" min-h-screen flex flex-col py-52 bg-[#0a0a35]  p-[32px] lg:py-24 items-center text-white">
      <h1 className="text-5xl lg:text-9xl  font-bold mb-6">404</h1>
      <h2 className="text-2xl font-semibold mb-4">Oops! Page not found</h2>
      <p className="text-gray-400 mb-8 text-center max-w-lg">
        The page you're looking for doesn't exist or has been moved. Try going
        back to the homepage or explore other sections.
      </p>

      <Link
        to="/"
        className="flex items-center space-x-2 bg-[#1791C8] text-white py-3 px-6 rounded-full hover:bg-blue-600 transition duration-300"
      >
        <FaHome className="text-lg" />
        <span>Back to Home</span>
      </Link>
    </div>
  );
};

export default NotFound;
