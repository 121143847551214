// import React from "react";

// const LegalDisclaimer = () => {
//   return (
//     <div className="p-[32px] lg:p-[80px_110px_80px_110px] flex items-center justify-center bg-[#0b0527] text-gray-200">
//       <div className="flex flex-col gap-2">
//         <h1 className="text-3xl font-bold text-center mb-4">
//           Legal Disclaimer
//         </h1>

//         <h2 className="text-lg md:text-2xl font-semibold mt-6">
//           No Guarantee of Success
//         </h2>
//         <p className="mb-4">
//           Completion of our courses does not imply any guaranteed professional
//           success.
//         </p>

//         <h2 className="text-lg md:text-2xl font-semibold mt-6">
//           Limitation of Liability
//         </h2>
//         <p className="mb-4">
//           We are not liable for any damages resulting from your use of our
//           website or courses.
//         </p>

//         <h2 className="text-lg md:text-2xl font-semibold mt-6">
//           Professional Advice Disclaimer
//         </h2>
//         <p className="mb-4">
//           The courses do not constitute professional or legal advice, and you
//           should consult qualified professionals for personalized assistance.
//         </p>

//         <h2 className="text-lg md:text-2xl font-semibold mt-6">
//           No Refund Policy
//         </h2>
//         <p className="mb-4">
//           All sales are final. We do not offer refunds for any purchased
//           courses.
//         </p>
//       </div>
//     </div>
//   );
// };

// export default LegalDisclaimer;

import React from "react";

const LegalDisclaimer = () => {
  return (
    <div className="p-[32px] lg:p-[80px_110px_80px_110px] flex items-center justify-center bg-[#0b0527] text-gray-200">
      <div className="flex flex-col gap-4 xl:pr-80 xl:pl-80">
        <h1 className="text-3xl font-bold text-center mb-4">
          Legal Disclaimer
        </h1>

        <h2 className="text-lg md:text-2xl font-semibold mt-6">
          No Guarantee of Success
        </h2>
        <p className="mb-4">
          Completion of our courses does not imply any guaranteed professional
          success. The skills, knowledge, and techniques you acquire through our
          content are intended to aid in personal development and understanding.
          Outcomes depend on individual effort, practice, and external factors.
          Success is subjective, and we do not guarantee specific results or
          achievements in professional settings.
        </p>

        <h2 className="text-lg md:text-2xl font-semibold mt-6">
          Limitation of Liability
        </h2>
        <p className="mb-4">
          We are not liable for any damages that may arise from your use of our
          website, courses, or any content provided. This includes, but is not
          limited to, direct or indirect financial losses, loss of data, or
          personal injury resulting from the application of information obtained
          from our courses. By using our platform, you accept full
          responsibility for any consequences stemming from your engagement with
          the material.
        </p>

        <h2 className="text-lg md:text-2xl font-semibold mt-6">
          Professional Advice Disclaimer
        </h2>
        <p className="mb-4">
          The courses and materials we offer are for informational and
          educational purposes only and do not constitute professional, legal,
          or financial advice. Any decisions or actions you take based on the
          information presented in our courses are your own responsibility. We
          recommend consulting with qualified professionals in your area of need
          for personalized guidance and advice, particularly for complex or
          critical matters.
        </p>

        <h2 className="text-lg md:text-2xl font-semibold mt-6">
          No Refund Policy
        </h2>
        <p className="mb-4">
          All sales made through our platform are final, and we do not offer
          refunds for any courses, subscriptions, or digital content purchases.
          This policy is due to the immediate access and downloadable nature of
          digital materials provided. We advise carefully reviewing course
          descriptions and sample content before purchasing to ensure they meet
          your needs and expectations, as refunds will not be processed.
        </p>

        <h2 className="text-lg md:text-2xl font-semibold mt-6">
          Intellectual Property Rights
        </h2>
        <p className="mb-4">
          All materials, including but not limited to course content, videos,
          text, and graphics, are protected by copyright and intellectual
          property laws. Unauthorized reproduction, distribution, or commercial
          use of any material is strictly prohibited. Access to course content
          is intended solely for individual use, and sharing or reselling any
          content or access credentials is a violation of our terms.
        </p>
      </div>
    </div>
  );
};

export default LegalDisclaimer;
