import React from "react";
import whyChooseLogoIcon1 from "../../Assests/Home/whyChooseLogoIcon1.svg";
import whyChooseLogoIcon2 from "../../Assests/Home/whyChooseLogoIcon2.svg";
import whyChooseLogoIcon3 from "../../Assests/Home/whyChooseLogoIcon3.svg";
import whyChooseLogoIcon4 from "../../Assests/Home/whyChooseLogoIcon4.svg";

const data = [
  {
    id: 1,
    logo: whyChooseLogoIcon1,
    title: "Access Anywhere",
    content:
      "Does not matter, where are you, you can access anywhere and get it",
  },
  {
    id: 2,
    logo: whyChooseLogoIcon2,
    title: "Friendly Admin",
    content:
      "We are proud to have a friendly and approachable admin team on our platform",
  },
  {
    id: 3,
    logo: whyChooseLogoIcon3,
    title: "Technical Support",
    content:
      "We are proud to have a friendly and approachable admin team on our platform",
  },
  {
    id: 4,
    logo: whyChooseLogoIcon4,
    title: "Learn at your time",
    content:
      "Does not matter, where are you, you can access anytime and read it",
  },
];

const WhyChooseUs = () => {
  return (
    <div 
    id="whyus"
    className="bg-[#0b0527] h-fit p-[32px] text-white  lg:p-[40px__110px]">
      <h2
        data-aos="fade-left"
        className="mulish-thin text-3xl text-center  tracking-[1px] lg:text-[45px] "
      >
        Why <span className="text-[#E22028]"> Choose</span> Us
      </h2>

      <p className="text-md  text-gray-300  lg:text-[18px] leading-6 lg:leading-[34px] mt-4 mb-8 lg:mb-8 lg:mt-8 px-2 lg:px-[250px] text-center tracking-wide ">
        We are dedicated to equipping individuals with the knowledge and skills
        needed to achieve financial success and build thriving businesses
        through our expertly crafted courses.
      </p>

      <div className="grid grid-cols-1  gap-8 md:grid-cols-2 lg:grid-cols-4 ">
        {data &&
          data.length > 0 &&
          data.map((item) => {
            return <Card key={item.id} item={item} />;
          })}
      </div>
    </div>
  );
};

export default WhyChooseUs;

function Card({ item }) {
  return (
    <div
      data-aos={`${item.id % 2 == 0 ? "fade-right" : "fade-left"}`}
      className="p-[32px] bg-[#140C3A] flex items-center flex-col  gap-3"
    >
      <img className="w-auto h-12" src={item?.logo} alt={item?.title} />
      <h3 className="mulish-thin text-[18px] text-center text-[#89D2F0] leading-[34px] tracking-wider ">
        {item?.title}
      </h3>
      <p className="text-[#858293] text-center mulish-thin text-[12px]  leading-[23px] tracking-wide">
        {item?.content}
      </p>
    </div>
  );
}
