const getToken = () => {
  if (typeof window !== "undefined") {
    const storedToken = localStorage.getItem("agm-token");
    return storedToken ? JSON.parse(storedToken) : null;
  }
};

let header = {
  authorization: `bearer ${getToken()}`,
  "Content-Type": "application/json",
};

const apiHeader = {
  headers: { ...header },
};

export default apiHeader;
