import React from "react";
import { useIntersectionObserver } from "../../Utils/useCountHelper";
import CountUp from "react-countup";

const data = [
  {
    id: 1,
    count: 3,
    role: "Experienced Trainer",
  },
  {
    id: 2,
    count: 3,
    role: "Courses Rolling Out",
  },
  {
    id: 3,
    count: 100,
    role: "Trained Students",
  },
  {
    id: 4,
    count: 3,
    role: "Community Earning ",
  },
];
const OurAchievements = () => {
  return (
    <div
      id="about"
      className="bg-[#0b0527] h-fit p-[32px] text-white  lg:p-[40px__110px]"
    >
      <h2
        data-aos="fade-right"
        className="mulish-thin text-3xl text-center  tracking-[1px] lg:text-[45px] "
      >
        <span className="text-[#E22028]"> Our </span>Achievements
      </h2>

      <p
        data-aos="fade-left"
        className="text-md text-gray-300 lg:text-[18px] leading-6 lg:leading-[34px] mt-4 mb-8 lg:mb-8 lg:mt-8 px-2 lg:px-[250px] text-center tracking-wide "
      >
        Empowering professionals with industry-leading skills, we take pride in
        our high satisfaction rate and partnerships with top companies,
        delivering impactful learning experiences.
      </p>

      <div className="grid grid-cols-1 gap-8 md:grid-cols-2  lg:grid-cols-4 ">
        {data &&
          data.length > 0 &&
          data.map((item) => {
            return <Card key={item.id} item={item} />;
          })}
      </div>

      <h2
        data-aos="fade-right"
        className="mulish-thin text-3xl text-center mt-8 md:mt-16 tracking-[1px] lg:text-[45px] "
      >
        Latest <span className="text-[#E22028]"> Featured </span>Packages
      </h2>

      <p
        data-aos="fade-left"
        className="text-md  text-gray-300  lg:text-[18px] leading-6 lg:leading-[34px] mt-4 mb-4 lg:mb-8 lg:mt-8 px-2 lg:px-[250px] text-center tracking-wide "
      >
        Explore our specially curated packages that boost your skills and
        maximize learning potential, designed to fit your unique career goals!
      </p>
    </div>
  );
};

export default OurAchievements;

function Card({ item }) {
  const [ref, isVisible] = useIntersectionObserver({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className={`${
        item.id == 1 ? "bg-[#09044D] " : "bg-[#070340]"
      } p-[32px]  rounded-md flex items-center flex-col gap-2 `}
    >
      <h3 className="mulish-thin text-[50px] font-bold text-white leading-[50px] tracking-wider ">
        <span>
          {item.count != 100 && "0"}
          {isVisible && <CountUp start={0} end={item?.count} duration={5} />}+
        </span>
      </h3>
      <p className="text-[#858293] text-center mulish-thin text-[18px]   leading-[34px] tracking-wide">
        {item?.role}
      </p>
    </div>
  );
}
