import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="p-[32px] lg:p-[80px_110px_80px_110px] flex items-center justify-center bg-[#0b0527] text-gray-200">
      <div className="flex flex-col gap-4 lg:mx-56">
        <h1 className="text-3xl font-bold text-center mb-4">Privacy Policy</h1>

        <h2 className="text-lg md:text-2xl font-semibold mt-6">
          Information We Collect
        </h2>
        <p className="mb-4">
          We collect personal information you provide, such as your name, email
          address, payment details, and data on your course activity.
          Additionally, we gather device information, IP address, and browsing
          data to enhance the platform's performance. This data helps us verify
          your identity, manage your account, and deliver course content
          tailored to your needs.
        </p>

        <h2 className="text-lg md:text-2xl font-semibold mt-6">
          How We Use Your Information
        </h2>
        <p className="mb-4">
          Your information enables us to process transactions, personalize your
          learning experience, provide customer support, and communicate with
          you regarding updates, offers, and promotions. We analyze usage data
          to enhance platform features and deliver a customized learning
          experience. Occasionally, we may use anonymized data to improve course
          content and overall user experience.
        </p>

        <h2 className="text-lg md:text-2xl font-semibold mt-6">
          Data Protection and Security
        </h2>
        <p className="mb-4">
          We prioritize the security of your data by implementing
          industry-standard encryption and security protocols. Your data is
          accessible only to authorized personnel who follow strict
          confidentiality agreements. We monitor our systems regularly to detect
          and address potential vulnerabilities, ensuring that your personal
          information is protected against unauthorized access or misuse at all
          times.
        </p>

        <h2 className="text-lg md:text-2xl font-semibold mt-6">
          Cookies and Tracking Technologies
        </h2>
        <p className="mb-4">
          Our website uses cookies to improve functionality and track usage
          patterns. These cookies help us remember your preferences, optimize
          page loading times, and enhance your overall experience. You can
          manage or disable cookies through your browser settings; however,
          please note that disabling cookies may affect certain features and
          functionality of our website.
        </p>

        <h2 className="text-lg md:text-2xl font-semibold mt-6">
          Third-Party Disclosure
        </h2>
        <p className="mb-4">
          We do not sell, trade, or otherwise transfer your personal information
          to outside parties, except when necessary for operations. Trusted
          partners who help operate our site or provide services are bound by
          strict confidentiality obligations. We may also release your
          information to comply with legal obligations, enforce our site
          policies, or protect rights, property, or safety.
        </p>

        <h2 className="text-lg md:text-2xl font-semibold mt-6">
          Data Retention
        </h2>
        <p className="mb-4">
          We retain your personal data only as long as needed to provide our
          services and comply with legal requirements. After this period, we
          securely delete or anonymize your information. Retaining your data
          allows us to enhance services, maintain historical records, and meet
          regulatory standards, ensuring that we serve you effectively and
          responsibly.
        </p>

        <h2 className="text-lg md:text-2xl font-semibold mt-6">Your Rights</h2>
        <p className="mb-4">
          You have the right to access, modify, or delete your personal data. If
          you wish to exercise these rights, contact us at{" "}
          <a
            className="text-gray-100 text-md"
            target="_blank"
            href="mailto:ammarketing921@gmail.com"
          >
            ammarketing921@gmail.com
          </a>
          . We may require identity verification to prevent unauthorized access
          to your account information, ensuring that your privacy rights are
          upheld securely.
        </p>

        <h2 className="text-lg md:text-2xl font-semibold mt-6">
          Children’s Privacy
        </h2>
        <p className="mb-4">
          Our services are not intended for children under 13, and we do not
          knowingly collect information from them. If we learn that we have
          unintentionally collected data from a child under 13, we will delete
          it immediately. We encourage parents and guardians to monitor online
          activities and inform us if they suspect any unauthorized information
          sharing.
        </p>

        <h2 className="text-lg md:text-2xl font-semibold mt-6">
          International Data Transfers
        </h2>
        <p className="mb-4">
          Our operations and data storage may involve international data
          transfers. We comply with applicable data protection laws by
          implementing measures to safeguard data during transfers across
          borders. This includes using secure servers and encryption practices,
          so regardless of your location, your information is handled with the
          utmost care and legal compliance.
        </p>

        <h2 className="text-lg md:text-2xl font-semibold mt-6">
          Policy Changes
        </h2>
        <p className="mb-4">
          We may update our Privacy Policy periodically to reflect changes in
          our practices or legal requirements. All modifications will be posted
          on this page with an updated revision date. By continuing to use our
          services after these changes, you acknowledge and agree to the updated
          terms. We recommend reviewing this policy periodically.
        </p>

        <h2 className="text-lg md:text-2xl font-semibold mt-6">Contact Us</h2>
        <p className="mb-4">
          For any questions or concerns regarding this Privacy Policy, please
          reach out to us at{" "}
          <a
            className="text-gray-100  text-md"
            target="_blank"
            href="mailto:ammarketing921@gmail.com"
          >
            ammarketing921@gmail.com
          </a>
          . We are committed to safeguarding your privacy and will address any
          inquiries or requests you may have promptly and professionally.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
