

import React from "react";

const FAQ = () => {
  return (
    <div className="p-[32px] lg:p-[80px_110px_80px_110px] flex items-center justify-center bg-[#0b0527] text-gray-200">
      <div className="flex flex-col gap-6  xl:pr-80 xl:pl-80">
        <h1 className="text-3xl font-bold mb-4 text-center">
          Frequently Asked Questions
        </h1>

        <h2 className="text-xl font-semibold">
          Can I access the course on multiple devices?
        </h2>
        <p className="mb-4">
          Yes, our platform is compatible with a range of devices, allowing you
          to study on your smartphone, tablet, or desktop computer. This
          flexibility enables you to learn from anywhere, at any time.
        </p>

        <h2 className="text-xl font-semibold">
          How long will I have access to the course?
        </h2>
        <p className="mb-4">
          Course access is provided for a lifetime unless otherwise specified.
          This allows you to revisit the material as needed, so you can continue
          learning at your own pace without any time limitations.
        </p>

        <h2 className="text-xl font-semibold">
          Are there prerequisites for the courses?
        </h2>
        <p className="mb-4">
          Yes, each course may have specific prerequisites, which are outlined
          in the course description. These prerequisites ensure that you have
          the foundational knowledge needed to succeed in more advanced topics.
        </p>

        <h2 className="text-xl font-semibold">
          Can I get a certificate after completing a course?
        </h2>
        <p className="mb-4">
          Absolutely. Upon successful completion of a course, you will receive a
          certificate that acknowledges your achievement. This can be valuable
          for showcasing your skills to employers or adding to your professional
          portfolio.
        </p>

        <h2 className="text-xl font-semibold">What is your refund policy?</h2>
        <p className="mb-4">
          We adhere to a strict no-refund policy for all course purchases. This
          policy is in place to maintain the integrity of our course offerings
          and to ensure fair access to all enrolled learners.
        </p>

        <h2 className="text-xl font-semibold">
          How can I contact support if I have issues with the course?
        </h2>
        <p className="mb-4">
          If you encounter any issues, please reach out through our 'Contact
          Support' section. Our dedicated team is available to assist you with
          any questions or technical difficulties.
        </p>

        <h2 className="text-xl font-semibold">
          Can I download the course material for offline access?
        </h2>
        <p className="mb-4">
          Currently, all course materials are accessible online only. This
          allows us to provide updates and maintain the quality of content,
          ensuring that you have access to the latest information.
        </p>

        <h2 className="text-xl font-semibold">
          Can I interact with the instructor or other students?
        </h2>
        <p className="mb-4">
          Yes, some courses include interactive features like discussion forums
          and Q&A sessions. These options foster a collaborative learning
          environment and enable you to gain insights from both instructors and
          peers.
        </p>
      </div>
    </div>
  );
};

export default FAQ;
