import React, { useEffect, useState } from "react";
import ourTestimonalImg1 from "../../Assests/Home/ourTestimonalImg1.jpg";
import ourTestimonalImg2 from "../../Assests/Home/ourTestimonalImg2.jpg";
import ourTestimonalImg3 from "../../Assests/Home/ourTestimonalImg3.jpg";
import ourTestimonalImg4 from "../../Assests/Home/ourTestimonalImg4.jpg";
import ourTestimonalImg5 from "../../Assests/Home/ourTestimonalImg5.jpg";
import { useWindowSize } from "../../Utils/useWindowSize";

const testimonials = [
  {
    id: 3,
    name: "MOOLCHAND",
    company: "ICICI BANK",
    image: ourTestimonalImg3,
    feedback:
      "After taking the Stock Market course, I felt confident making informed decisions. Great for beginners!",
  },
  {
    id: 2,
    name: "SANDEEP SAMOTA",
    company: "BHARTI AIRTEL",
    image: ourTestimonalImg2,
    feedback:
      "The Google Ads course provided me with strategies that brought in new clients and improved our online presence.",
  },
  {
    id: 1,
    name: "SACHIN KHIJURIYA",
    company: "HINDUSTAN UNILEVER",
    image: ourTestimonalImg1,
    feedback:
      "The Stock Market course was comprehensive. It provided me with a good foundation to grow my wealth.",
  },
  {
    id: 3,
    name: "MOOLCHAND",
    company: "ICICI BANK",
    image: ourTestimonalImg3,
    feedback:
      "After taking the Stock Market course, I felt confident making informed decisions. Great for beginners!",
  },
  {
    id: 2,
    name: "SANDEEP SAMOTA",
    company: "BHARTI AIRTEL",
    image: ourTestimonalImg2,
    feedback:
      "The Google Ads course provided me with strategies that brought in new clients and improved our online presence.",
  },
  {
    id: 5,
    name: "MAHESH KUMAR",
    company: "TATA CONSULTANCY SERVICES",
    image: ourTestimonalImg5,
    feedback:
      "Thanks to the Google Ads course, I landed my first big client. Highly recommended!",
  },

  {
    id: 4,
    name: "VIKRAM SINGH",
    company: "WIPRO",
    image: ourTestimonalImg4,
    feedback:
      "The Facebook Ads course gave me the tools to drive engagement and reach our target audience effectively.",
  },

  {
    id: 5,
    name: "MAHESH KUMAR",
    company: "TATA CONSULTANCY SERVICES",
    image: ourTestimonalImg5,
    feedback:
      "Thanks to the Google Ads course, I landed my first big client. Highly recommended!",
  },
  {
    id: 2,
    name: "SANDEEP SAMOTA",
    company: "BHARTI AIRTEL",
    image: ourTestimonalImg2,
    feedback:
      "The Google Ads course provided me with strategies that brought in new clients and improved our online presence.",
  },
];

const OurTestimonial = () => {
  const [currentPage, setCurrentPage] = useState(0);
  let testimonialsPerPage = 3;

  const { width } = useWindowSize();

  if (width < 1024) {
    testimonialsPerPage = 1;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPage(
        (prev) =>
          (prev + 1) % Math.ceil(testimonials.length / testimonialsPerPage)
      );
    }, 2000);

    return () => clearInterval(interval);
  }, [testimonialsPerPage]);

  const nextPage = () => {
    setCurrentPage(
      (prev) =>
        (prev + 1) % Math.ceil(testimonials.length / testimonialsPerPage)
    );
  };

  const prevPage = () => {
    setCurrentPage(
      (prev) =>
        (prev - 1 + Math.ceil(testimonials.length / testimonialsPerPage)) %
        Math.ceil(testimonials.length / testimonialsPerPage)
    );
  };

  const currentTestimonials = testimonials.slice(
    currentPage * testimonialsPerPage,
    currentPage * testimonialsPerPage + testimonialsPerPage
  );

  return (
    <div id="review" className="p-[32px] bg-[#0B0527]   lg:p-[60px__110px]">
      <h2
        data-aos="fade-top"
        className="text-3xl md:text-4xl font-semibold text-center text-white mb-8"
      >
        Our <span className="text-[#E22028]"> Testimonial</span>
      </h2>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {currentTestimonials.map((testimonial, index) => (
          <div
            key={index}
            className=" rounded-md  p-6 text-center transition-transform duration-500 ease-in-out"
          >
            <img
              src={testimonial.image}
              alt={testimonial.name}
              className="w-28 h-28 object-cover rounded-full mx-auto mb-4"
            />
            <p className="text-gray-400 mb-4">{testimonial.feedback}</p>
            <p className="text-sm font-semibold text-gray-300">
              {testimonial.name.toUpperCase()} -{" "}
              {testimonial.company.toUpperCase()}
            </p>
          </div>
        ))}
      </div>

      <div className="flex justify-center gap-2 items-center mt-8">
        <button
          onClick={prevPage}
          className="h-3 w-3 bg-gray-400 rounded-full text-white mr-2"
        ></button>
        <button
          onClick={nextPage}
          className="h-3 w-3 border border-white rounded-lg"
        ></button>
      </div>
    </div>
  );
};

export default OurTestimonial;
