import React from "react";
import ourCourseImg1 from "../../Assests/Home/ourCoursesImg1.svg";
import ourCourseImg2 from "../../Assests/Home/ourCoursesImg2.svg";
import ourCourseImg3 from "../../Assests/Home/ourCoursesImg3.svg";

const data = [
  {
    id: 1,
    logo: ourCourseImg1,
  },

  {
    id: 2,
    logo: ourCourseImg2,
  },
  {
    id: 3,
    logo: ourCourseImg3,
  },
];

const OurCourses = () => {
  return (
    <div
      id="market"
      className="bg-[#0b0527] h-fit p-[32px] text-white  lg:p-[40px__110px]"
    >
      <h2
        data-aos="fade-right"
        className="mulish-thin text-3xl text-center  tracking-[1px] lg:text-[45px] "
      >
        Our <span className="text-[#E22028]"> Courses</span>
      </h2>

      <p
        data-aos="fade-left"
        className="text-md  text-gray-300  lg:text-[18px] leading-6 lg:leading-[34px] mt-4 mb-8 lg:mb-8 lg:mt-8 px-2 lg:px-[250px] text-center tracking-wide "
      >
       Our courses are tailored to cover every aspect of digital marketing and stock market investment, from basics to advanced strategies.
      </p>

      <div className="grid grid-cols-1 gap-8  md:grid-cols-3 ">
        {data &&
          data.length > 0 &&
          data.map((item) => {
            return <Card key={item.id} item={item} />;
          })}
      </div>
    </div>
  );
};

export default OurCourses;

function Card({ item }) {
  return (
    <div
      data-aos={`${item.id % 2 == 0 ? "fade-right" : "fade-left"}`}
      className="bg-[#140C3A]  border-[10px] border-[#005678] md:p-0 flex items-center flex-col  gap-3"
    >
      <img
        className="w-full h-full"
        src={item?.logo}
        alt={`itemLogo+${item.id}`}
      />
    </div>
  );
}
