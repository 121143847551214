import React from "react";

const RefundPolicy = () => {
  return (
    <div className="p-[32px] lg:p-[80px_110px_80px_110px] flex items-center justify-center bg-[#0b0527] text-gray-200">
      <div className="flex flex-col gap-8 lg:pr-48 lg:pl-48">
        <h1 className="text-3xl font-bold mb-4 text-center">Refund Policy</h1>

        <h2 className="text-xl font-semibold">
          Do you offer refunds on course purchases?
        </h2>
        <p className="mb-4">
          No, we maintain a strict no-refund policy for all course purchases.
          Once a course is accessed, we cannot issue a refund due to the digital
          nature of the content and immediate access provided upon purchase.
        </p>

        <h2 className="text-xl font-semibold">
          Can I request a refund if I am not satisfied with the course?
        </h2>
        <p className="mb-4">
          While we strive to ensure high-quality content, refunds are not
          offered based on personal dissatisfaction. We recommend reviewing the
          course details thoroughly before making a purchase to ensure it aligns
          with your expectations and needs.
        </p>

        <h2 className="text-xl font-semibold">
          Are there any exceptions to the no-refund policy?
        </h2>
        <p className="mb-4">
          Generally, there are no exceptions to our no-refund policy. However,
          if there are technical issues preventing access to the course content,
          please contact support. Our team will assist in resolving the issue.
        </p>

        <h2 className="text-xl font-semibold">
          Can I transfer my course purchase to another user if I can't use it?
        </h2>
        <p className="mb-4">
          Course purchases are non-transferable and cannot be shared with or
          transferred to another individual. Each purchase is linked to the
          specific user who made the transaction and cannot be reallocated.
        </p>

        <h2 className="text-xl font-semibold">
          Is there a refund for duplicate purchases?
        </h2>
        <p className="mb-4">
          If you accidentally made a duplicate purchase for the same course,
          please reach out to our support team within 24 hours. We can assist in
          resolving duplicate transactions, subject to verification.
        </p>

        <h2 className="text-xl font-semibold">
          How can I reach out if I have concerns about the refund policy?
        </h2>
        <p className="mb-4">
          If you have questions or concerns regarding our refund policy, please
          contact our support team. We’re here to clarify any uncertainties and
          assist with any issues you may encounter with your purchase.
        </p>
      </div>
    </div>
  );
};

export default RefundPolicy;
